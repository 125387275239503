import {
  IconClose,
  IconEdit,
  IconLocationPin,
  IconMapPin,
  IconSave
} from 'assets/images'
import { Button, SelectInput } from 'components'
import { MapsStyle } from 'constants/maps-style'
import { useFormikContext } from 'formik'
import GoogleMapReact, { ClickEventValue } from 'google-map-react'
import { t } from 'i18n'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { MarkerPosition, Report, Slope, UserInfo } from 'store/ducks'
import { ApplicationState } from '../../../../../../store'
import { ModalOverlay } from '../../../../../../styles/global'
import { FlexRowSpaceBetween } from '../../../../../Report/Landing/LandingReportHeader/styled'
import {
  GoogleMapContainer,
  H1,
  IconCloseContainer,
  LocationModal,
  MapPinContainer,
  RowItem,
  SaveButtonContainer,
  SwitchOptionContainer,
  TrailContainer,
  WeatherAndLocationActionIconContainer,
  WeatherAndLocationContainer,
  WeatherLabel
} from '../../styled'
import AddLocationHeatmap from './AddLcationHeatmap'

interface IMapPinComponentProps {
  lat: number
  lng: number
}

interface IAutomaticInfoLocation {
  user?: UserInfo
  report: Report
}

type OptionItem = {
  id: string | number
  label: string | number
}

const MapPinComponent: React.FC<IMapPinComponentProps> = () => (
  <MapPinContainer>
    <IconLocationPin />
  </MapPinContainer>
)

const AutomaticInfoLocation = ({ user, report }: IAutomaticInfoLocation) => {
  const [open, setOpen] = useState<boolean>(false)
  const [slopeList, setSlopeList] = useState<OptionItem[]>([])

  const {
    values: { trailMap = {}, heatmap, heatmap: { coordinates = {} } = {} },
    setFieldValue
  } = useFormikContext<Report>()

  const [includeLocation, setIncludeLocation] = useState<boolean>(
    trailMap.includeLocation ?? false
  )

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleChange = (event: ClickEventValue) => {
    setFieldValue('trailMap.geoLocation', {
      lat: event.lat,
      lng: event.lng
    })
  }

  const handleTrailChanged = (e: React.ChangeEvent | any) => {
    setFieldValue('trailMap.slope', e.target.value)
  }

  const filterSlopes = useCallback(
    (slope: Slope) => {
      if (!slope) {
        return false
      }

      const { types } = slope

      if (!types) {
        return false
      }

      for (const type of types) {
        if (type === report.season) {
          return true
        }
      }
      return false
    },
    [report.season]
  )

  useEffect(() => {
    const requestLocation = async () => {
      navigator.geolocation.getCurrentPosition(position => {
        if (position && position?.coords?.latitude) {
          const { latitude, longitude } = position?.coords || {}
          setFieldValue('trailMap.geoLocation', {
            lat: latitude,
            lng: longitude
          })
        }
      })
    }

    requestLocation()
  }, [setFieldValue])

  useEffect(() => {
    const listSlopes = async () => {
      const currentSeasonSlopes = user?.resort?.slopes?.filter(filterSlopes)

      const _slopes =
        currentSeasonSlopes?.map(slope => ({
          label: slope.name,
          id: slope.name
        })) || []

      setSlopeList(_slopes)
    }
    listSlopes()
  }, [filterSlopes, user])

  const handleChangeHeatmap = (
    coordinates: MarkerPosition & { reportId: string | undefined }
  ) => {
    setFieldValue('heatmap.coordinates', coordinates)
  }

  const handleClearPosition = () => {
    setFieldValue('heatmap', undefined)
  }

  const hasValue = includeLocation || heatmap
  return (
    <WeatherAndLocationContainer hasValue={!!hasValue}>
      <FlexRowSpaceBetween>
        <IconMapPin width={34} height={34} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 10,
            paddingTop: 10
          }}
        >
          <div>{t('location')}</div>
          <div>{t('plusTrailMap')}</div>
        </div>
      </FlexRowSpaceBetween>
      <WeatherAndLocationActionIconContainer style={{ flex: '1 1 66%' }}>
        <IconEdit onClick={toggleOpen} />
      </WeatherAndLocationActionIconContainer>
      {open && (
        <ModalOverlay>
          <LocationModal>
            <IconCloseContainer onClick={toggleOpen}>
              <WeatherLabel>{t`close`}</WeatherLabel>
              <IconClose />
            </IconCloseContainer>
            <TrailContainer>
              <H1>{t('slopeTrailName')}</H1>
              <AddLocationHeatmap
                handleOnChange={handleChangeHeatmap}
                handleClearPosition={handleClearPosition}
                coordinates={coordinates}
              />
              <RowItem width={20}>
                <SelectInput
                  name="trailMap.slope"
                  label="slopeTrail"
                  onChange={handleTrailChanged}
                  options={slopeList}
                  translateText={false}
                  value={trailMap.slope}
                />
              </RowItem>
            </TrailContainer>
            <SwitchOptionContainer>
              <WeatherLabel>{t('showLocationInReport')}</WeatherLabel>
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked: boolean) => {
                  setIncludeLocation(checked)
                  setFieldValue('trailMap.includeLocation', checked)
                }}
                checked={includeLocation}
              />
            </SwitchOptionContainer>
            <GoogleMapContainer>
              {includeLocation && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_KEY as string
                  }}
                  defaultCenter={{
                    lat: Number(
                      trailMap?.geoLocation?.lat || user?.resort?.location?.lat
                    ),
                    lng: Number(
                      trailMap?.geoLocation?.lng || user?.resort?.location?.lng
                    )
                  }}
                  options={{ styles: MapsStyle }}
                  defaultZoom={18}
                  onClick={handleChange}
                >
                  {includeLocation && trailMap?.geoLocation && (
                    <MapPinComponent
                      lat={Number(
                        trailMap?.geoLocation?.lat ||
                          user?.resort?.location?.lat
                      )}
                      lng={Number(
                        trailMap?.geoLocation?.lng ||
                          user?.resort?.location?.lng
                      )}
                    />
                  )}
                </GoogleMapReact>
              )}
            </GoogleMapContainer>
            <SaveButtonContainer style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Button
                buttonStyle={{ borderRadius: '8px' }}
                type="button"
                variant="primary"
                label="save"
                icon={<IconSave fill="#fff" />}
                onClick={toggleOpen}
              />
            </SaveButtonContainer>
          </LocationModal>
        </ModalOverlay>
      )}
    </WeatherAndLocationContainer>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data,
  report: state.report.data
})

export default connect(mapStateToProps)(AutomaticInfoLocation)
