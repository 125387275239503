import { Bar } from 'react-chartjs-2'
import ChartContainer from './ChartContainer'

interface BarChartProps {
  labels: string[]
  data: number[]
  title: string
}

const BarChart = ({ labels, data, title }: BarChartProps) => {
  if (!data) return null

  const biggestNum = Math.max(...data)
  const nearestBigNum = 10

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: Math.ceil(biggestNum / 10) * nearestBigNum,
        stepSize: 200,
        grid: {
          tickLength: 8,
          tickColor: 'transparent',
          color: '#155194',
          lineWidth: 1,
          borderWidth: 1,
          borderColor: '#155194'
        }
      },
      x: {
        stepSize: 200,

        grid: {
          display: false,
          borderWidth: 1,
          borderColor: '#155194',
          color: '#155194'
        }
      }
    },
    elements: {
      point: {
        pointStyle: 'circle'
      }
    },
    plugins: {
      datalabels: {
        color: '#fff'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    }
  }
  const colors = [
    '#F7CC00',
    '#0072BB',
    '#389FBF',
    '#9CBF38',
    '#38BF86',
    '#BF3838',
    '#8E44AD',
    '#E67E22',
    '#2ECC11',
    '#3498DB'
  ]

  const defaultData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors
      }
    ]
  }

  console.log({ labels, data, title })

  return (
    <ChartContainer title={title}>
      <Bar options={options} data={defaultData} />
    </ChartContainer>
  )
}

export default BarChart
