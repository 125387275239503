import { PersonalInformationConsent, Report, Seasons } from 'store/ducks'

export const ValidateReportSections = (report: Report): Report => {
  const {
    personalInformation,
    season,
    sequenceOfEvents,
    transport,
    treatedBy,
    investigationAndMonitoring,
    state,
    timestamps,
    injury,
    checklist
  } = report

  // validate personalInformation

  const {
    firstName,
    lastName,
    address,
    phoneNumber,
    gender,
    birthDate,
    place,
    postalCode,
    country,
    personalInformationConsent,
    skills,
    equipments,
    nationality
  } = personalInformation

  let showPersonInfoForm = false

  switch (personalInformationConsent) {
    case PersonalInformationConsent.PERMISSION_CONSENTED:
    case PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT:
      showPersonInfoForm = true
      break
    case PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT:
    case PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT:
      showPersonInfoForm = false
      break
  }

  if (showPersonInfoForm) {
    if (
      firstName &&
      lastName &&
      address &&
      phoneNumber &&
      gender &&
      nationality &&
      birthDate &&
      place &&
      postalCode &&
      country
    ) {
      report.personalInformation.personalInfoFormCompleted = true
    }
  } else {
    report.personalInformation.personalInfoFormCompleted = true
  }

  if (personalInformation.firstName && personalInformation.lastName) {
    report.patientName =
      personalInformation.firstName.toLowerCase() +
      ' ' +
      personalInformation.lastName.toLowerCase()
  }

  if (skills && skills.level) {
    report.personalInformation.skillsCompleted = true
  }

  if (equipments) {
    if (season === Seasons.WINTER) {
      if (equipments.skiEquipment && equipments.skiEquipment.type) {
        report.personalInformation.equipmentsCompleted = true
      }
    }

    if (season === Seasons.SUMMER) {
      if (equipments.bikeEquipment && equipments.bikeEquipment.type) {
        report.personalInformation.equipmentsCompleted = true
      }
    }
  }

  if (
    report.personalInformation.personalInformationConsent ===
      PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT ||
    report.personalInformation.personalInformationConsent ===
      PersonalInformationConsent.PERMISSION_CONSENTED
  ) {
    report.personalInfoCompleted =
      (report.personalInformation.personalInfoFormCompleted &&
        report.personalInformation.skillsCompleted &&
        report.personalInformation.equipmentsCompleted) ||
      false
  }

  if (
    report.personalInformation.personalInformationConsent ===
      PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT ||
    report.personalInformation.personalInformationConsent ===
      PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
  ) {
    report.personalInfoCompleted =
      (report.personalInformation.skillsCompleted &&
        report.personalInformation.equipmentsCompleted) ||
      false
  }

  // validate personalInformation

  // Validade injury section

  if (injury) {
    const { assumedInjury, injuredBodyPart } = injury

    if (assumedInjury && report.injury) {
      report.injury.assumedInjuryCompleted =
        assumedInjury.acuteSickness ||
        assumedInjury.concussion ||
        assumedInjury.cutsWounds ||
        assumedInjury.dead ||
        assumedInjury.dislocated ||
        assumedInjury.fracture ||
        assumedInjury.impactShock ||
        assumedInjury.other ||
        assumedInjury.sprain ||
        false
    }

    if (injuredBodyPart) {
      if (injuredBodyPart && report.injury) {
        report.injury.injuryBodyPartCompleted =
          injuredBodyPart.ankleFoot ||
          injuredBodyPart.arm ||
          injuredBodyPart.back ||
          injuredBodyPart.breast ||
          injuredBodyPart.buttock ||
          injuredBodyPart.calf ||
          injuredBodyPart.collarbone ||
          injuredBodyPart.hand ||
          injuredBodyPart.head ||
          injuredBodyPart.hip ||
          injuredBodyPart.knee ||
          false
      }
    }

    if (
      report &&
      (report.injury?.assumedInjuryCompleted ||
        report.injury?.injuryBodyPartCompleted)
    ) {
      report.injuryCompleted = true
    }
  }

  // sequence of events

  if (sequenceOfEvents) {
    if (
      report.sequenceOfEvents &&
      sequenceOfEvents.injuryLocation &&
      (sequenceOfEvents.injuryLocation.adaptedPath ||
        sequenceOfEvents.injuryLocation.closedDownSpeed ||
        sequenceOfEvents.injuryLocation.jump ||
        sequenceOfEvents.injuryLocation.naturalPath ||
        sequenceOfEvents.injuryLocation.other ||
        sequenceOfEvents.injuryLocation.outsidePreparedDownSpeed ||
        sequenceOfEvents.injuryLocation.preparedDownSpeed ||
        sequenceOfEvents.injuryLocation.pumptrack ||
        sequenceOfEvents.injuryLocation.roadGravelRoad ||
        sequenceOfEvents.injuryLocation.terrainParkAtElement ||
        sequenceOfEvents.injuryLocation.tractionElement ||
        sequenceOfEvents.injuryLocation.crosscountryTrack ||
        false)
    ) {
      report.sequenceOfEvents.injuryLocationCompleted = true
    }

    if (
      report.sequenceOfEvents &&
      sequenceOfEvents &&
      sequenceOfEvents.witnesses &&
      sequenceOfEvents?.witnesses?.length > 0
    ) {
      report.sequenceOfEvents.witnessCompleted = true
    }

    if (
      report.sequenceOfEvents &&
      (sequenceOfEvents.eventExplanation ||
        sequenceOfEvents.eventExplanationLocation ||
        sequenceOfEvents.fallYourself ||
        sequenceOfEvents.hitByAnotherPerson ||
        sequenceOfEvents.runOverAnotherPerson ||
        sequenceOfEvents.collisionWithObject ||
        sequenceOfEvents.lostControl ||
        sequenceOfEvents.drivenOutOfBerm ||
        sequenceOfEvents.crashObjectOnTrail ||
        sequenceOfEvents.lostBalanceAfterJump ||
        sequenceOfEvents.hitByAnotherCyclist)
    ) {
      report.sequenceOfEvents.eventExplanationCompleted = true
    }

    if (
      report.sequenceOfEvents?.eventExplanationCompleted ||
      report.sequenceOfEvents?.injuryLocationCompleted
    ) {
      report.sequenceOfEventsCompleted = true
    }
  }

  // timestamps

  if (timestamps) {
    const {
      notificationOfInjuryTime,
      skiPatrolAtInjurySite,
      pathPatrolAtInjurySite,
      departureFromInjurySiteTime,
      arrivedAtInjuryRoomTime,
      amkWarningTime,
      patientDeliveredTime
    } = timestamps
    if (
      notificationOfInjuryTime &&
      (skiPatrolAtInjurySite || pathPatrolAtInjurySite) &&
      departureFromInjurySiteTime &&
      arrivedAtInjuryRoomTime &&
      amkWarningTime &&
      patientDeliveredTime
    ) {
      report.timeStampsCompleted = true
    }
  }

  // state
  if (state) {
    report.stateCompleted = true
  }

  // transport

  if (transport) {
    let transportAtResort = false
    let furtherTransport = false

    if (
      transport.transportAtResort &&
      (transport.transportAtResort.atv ||
        transport.transportAtResort.own ||
        transport.transportAtResort.pulk ||
        transport.transportAtResort.snowScooter ||
        transport.transportAtResort.scooterPulk ||
        transport.transportAtResort.atvUtv ||
        transport.transportAtResort.walkingCyclingYourself ||
        transport.transportAtResort.stretcher ||
        transport.transportAtResort.fireAndRescue ||
        false)
    ) {
      transportAtResort = true
    }

    if (
      transport.furtherTransport &&
      (transport.furtherTransport.airAmbulance ||
        transport.furtherTransport.ambulance ||
        transport.furtherTransport.privateCar ||
        transport.furtherTransport.stayingAtSkiResort ||
        transport.furtherTransport.taxi ||
        transport.furtherTransport.stiPTrspEmergencyRoom ||
        false)
    ) {
      furtherTransport = true
    }

    if (transportAtResort && furtherTransport) {
      report.transportationCompleted = true
    }
  }

  // investigation and monitoring

  if (
    investigationAndMonitoring &&
    investigationAndMonitoring.monitoring.length > 0
  ) {
    report.investigationAndMonitoringCompleted = true
  }

  // TREATED BY

  if (
    treatedBy &&
    (treatedBy.contactDoctorIfConditionsWorsens ||
      treatedBy.otherEmployee ||
      treatedBy.skiPatrol ||
      treatedBy.seeDoctorIfConditionsWorsens ||
      treatedBy.emergencyRoom ||
      treatedBy.ambulanceOnSite ||
      treatedBy.doctorOnSite ||
      treatedBy.firstAidTrained ||
      false)
  ) {
    report.treatedByCompleted = true
  }

  if (
    checklist &&
    (checklist.clearAirways ||
      checklist.hlr ||
      checklist.bandaging ||
      checklist.reportFracture ||
      checklist.splint ||
      checklist.stretchSplint ||
      checklist.neckCollar ||
      checklist.vacuumMattress ||
      checklist.backboard ||
      checklist.woundCleanser ||
      checklist.fallForward ||
      checklist.externalHeat ||
      false)
  ) {
    report.checklistCompleted = true
  }
  return report
}
