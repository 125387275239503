import styled from 'styled-components'
import { theme } from 'styles/theme'

const FormContainer = styled.div`
  margin: 20px 60px;
`

const FormSectionSubtitle = styled.h4`
  font-size: ${theme.textSizes.normal};
  font-weight: normal;
  color: ${theme.colors.black};
`

const FlexRow = styled.div`
  display: flex;
`

const RowItem = styled.div<{ width?: number }>`
  margin: 0 20px 0 0;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
`

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.gray};
  margin: 20px;
  svg {
    fill: ${theme.colors.gray} !important;
    width: 16px;
    height: 14px;
    margin-left: 5px;
  }
`

const H1 = styled.h1`
  font-size: ${theme.textSizes.h1};
`

const ReportSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  padding: 40px;
  small {
    font-size: ${theme.textSizes.smaller};
  }
  h4 {
    font-size: ${theme.textSizes.large};
    font-weight: 500;
    margin: 10px 0;
  }
  p {
    font-size: 18px;
    margin: 5px 0;
  }
`

const ValidationContainer = styled.div`
  padding: 60px;
  color: ${theme.colors.gray};
`

const ValidationTitle = styled.h3`
  font-size: ${theme.textSizes.normal};
`

const ValidationDescription = styled.p`
  font-size: ${theme.textSizes.small};
  max-width: 300px;
`

const ValidationSectionItem = styled.div`
  width: 45%;
  padding: 20px;
  margin: 10px 20px 10px 0;
  border: 1px solid ${theme.colors.lighterGray};

  svg {
    cursor: pointer;
    margin-top: 30px;
    float: right;
  }

  h4 {
    font-size: ${theme.textSizes.normal};
    font-weight: 500;
    margin-bottom: 12px;
  }

  small {
    display: inline-block;
    font-size: ${theme.textSizes.small};
    margin-bottom: 15px;
  }

  p {
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
  }
`

const ConfirmationSectionContainer = styled.div`
  display: flex;
`

const ConfirmationSectionItem = styled.div`
  width: 60%;
  padding: 0 60px;

  &:first-child {
    width: 40%;
  }
`

const WeatherAndLocationContainer = styled.div<{ hasValue?: boolean }>`
  position: relative;
  width: 259.2px;
  display: flex;
  flex-direction: column;
  height: 157px;
  margin: 50px 30px 0 0;
  padding: 9px;
  border: solid 1px ${theme.colors.lighterGray};
  border-bottom: ${({ hasValue }) =>
    hasValue ? `6px solid ${theme.colors.green}` : ''};
  color: ${theme.colors.gray};

  textarea {
    width: 100%;
    margin-top: 20px;
    border-color: ${theme.colors.lightGray};
  }
`

const WeatherAndLocationActionIconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;

  justify-content: flex-end;
  flex-direction: column;
`

const WeatherTemperatureValue = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  justify-content: center;
`

const WeatherModal = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  z-index: 3;
  padding: 30px;
  min-width: 410px;
  border: 1px solid ${theme.colors.lighterGray};
`

const WeatherModalLeftColumn = styled.div`
  width: 60%;
`

const WeatherModalRightColumn = styled.div`
  border-left: 1px solid ${theme.colors.lighterGray};
  padding: 5px;
`

const WeatherLabel = styled.p`
  font-size: ${theme.textSizes.smaller};
  color: ${theme.colors.lightGray};
`

const WeatherValue = styled.p`
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.gray};
  font-weight: 500;
  margin: 0;
`

const IconCloseContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  cursor: pointer;

  & svg {
    fill: ${theme.colors.lighterGray} !important;
  }
`

const LocationModal = styled.div`
  height: 75vh;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${theme.colors.white};
  box-shadow: -10px 3px 30px 15px rgba(0, 0, 0, 0.15);
  overflow: scroll;
`

const GoogleMapContainer = styled.div`
  height: 40%;
  min-height: 40%;
  padding: 25px;
`

const TrailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px 25px 25px 25px;
`

const MapPinContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  transform: translate(-50%, -50%);
`

const SwitchOptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 25px;
`

const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

export {
  BackButtonContainer,
  ConfirmationSectionContainer,
  ConfirmationSectionItem,
  FlexRow,
  FormContainer,
  FormSectionSubtitle,
  GoogleMapContainer,
  H1,
  IconCloseContainer,
  LocationModal,
  MapPinContainer,
  ReportSummaryContainer,
  RowItem,
  SaveButtonContainer,
  SwitchOptionContainer,
  TrailContainer,
  ValidationContainer,
  ValidationDescription,
  ValidationSectionItem,
  ValidationTitle,
  WeatherAndLocationActionIconContainer,
  WeatherAndLocationContainer,
  WeatherLabel,
  WeatherModal,
  WeatherModalLeftColumn,
  WeatherModalRightColumn,
  WeatherTemperatureValue,
  WeatherValue
}
