import DatePicker from 'react-date-picker'
import InputMask from 'react-input-mask'
import ReactSlider from 'react-slider'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import IconCameraSrc from '../../assets/images/camera.svg'

const InputGroup = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
`

const InputLabel = styled.label`
  font-size: ${theme.textSizes.smaller};
  color: ${theme.colors.black};
`

const InputContainer = styled.input`
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  padding-left: 10px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &[type='file'] {
    display: none;
  }
`

const PhoneInputContainer = styled.div`
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  img {
    margin-right: 10px;
  }
`

const MaskedInputContainer = styled(InputMask)`
  height: 40px;
  display: flex;
  flex-grow: 1;
  border: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &[type='file'] {
    display: none;
  }
`

const InputIconContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: calc(50% - 18px);
`

const SuccessLabel = styled.small`
  margin-top: 5px;
  color: ${theme.colors.green};
`

const TextAreaContainer = styled.textarea`
  margin-top: 15px;
  height: 100px;
  padding: 10px;
  width: 97% !important;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  resize: none;
  flex-grow: 1 1;
`

const SelectContainer = styled.select`
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  padding-left: 10px;
  background-color: ${theme.colors.white};
`

const DropdownUserContainer = styled('div')`
  cursor: pointer;
  z-index: 2;
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  padding-left: 10px;
  background-color: ${theme.colors.white};
`

const DropdownUserListContainer = styled.div`
  overflow-y: scroll;
  border-radius: 4px;
  max-height: 200px;
  border: 1px solid ${theme.colors.black};
  color: ${theme.colors.black};
`

const DropdownUserListItem = styled.ul`
  padding: 10px;
  margin: 0;
  background: ${theme.colors.white};
  box-sizing: border-box;
`

const SelectedUserItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 17px 6px;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.black};

  svg {
    cursor: pointer;
  }
`

const InputDateContainer = styled(DatePicker)`
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  padding-left: 10px;

  div {
    border: none;
  }
  .react-calendar__tile--active {
    background-color: ${theme.colors.primary};
  }
`

const InputTimeGroup = styled.div`
  display: flex;
  align-items: center;
`

const InputTimeLabel = styled.label`
  min-width: 250px;
  margin: 20px;
  font-size: ${theme.textSizes.normal};
  color: ${theme.colors.black};
`

const InputTimeContainer = styled.input`
  max-width: 80px;
  height: 40px;
  margin: 9px 0 0;
  border-radius: 4px;
  border: solid 1px ${theme.colors.black};
  text-align: center;
  font-size: ${theme.textSizes.normal};
  color: ${theme.colors.black};
`

const InputCollapsableContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 200px;
  height: 40px;
  margin: 9px 0 0;
  border-color: solid;
  border-width: 1px;
  border-style: ${({ active }) => (active ? 'none none solid' : 'solid')};
  border-radius: ${({ active }) => (active ? 0 : '4px')};
  padding: 0 10px;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.black};

  svg {
    cursor: pointer;
    width: 20px;
    fill: ${theme.colors.black} !important;
  }
`

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  input[type='radio'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  div {
    display: flex;
    flex-wrap: wrap;
  }
`

const RadioGroupLabel = styled.p`
  margin-bottom: 15px;
  margin-top: 0;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.black};
`

const RadioGroupItemLabel = styled.label<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 145px;
  height: 40px;
  background-color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.white};
  color: ${({ active }) => (active ? theme.colors.white : theme.colors.black)};
  border: ${({ active }) =>
    active ? 'none' : `1px solid ${theme.colors.black}`};
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 5px;
  padding: 0 30px 0 10px;
  transition: 0.2s;
`

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  input[type='checkbox'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
`

const CheckboxWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CheckboxGroupLabel = styled.div`
  position: relative;
  margin-top: 0;
  font-size: ${theme.textSizes.small};
  color: ${theme.colors.black};
`

const CheckboxGroupItemLabel = styled.label<{ active: boolean }>`
  cursor: pointer;
  width: 190px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  background-color: ${({ active }) =>
    active ? theme.colors.primary : theme.colors.white};
  color: ${({ active }) => (active ? theme.colors.white : theme.colors.black)};
  border: ${({ active }) =>
    active ? '1px solid transparent' : `1px solid ${theme.colors.black}`};
  border-radius: 4px;
  margin: 0 25px 10px 0;
  padding-left: 15px;

  p {
    margin-top: 40px;
  }

  .checkmark {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 14px;
    width: 14px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: ${theme.colors.black};

    &::before {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 6px;
      height: 6px;
      background: #9eb3e8;
      transform: translate(-50%, -50%);
      opacity: ${({ active }) => (active ? 1 : 0)};
      visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
      transition: 0.2s;
    }
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.white};
  }
`

const ReactSliderLabel = styled.label`
  margin: 30px;
  font-size: ${theme.textSizes.large};
  color: ${theme.colors.black};
`

const ReactSliderContent = styled.div`
  display: flex;
  align-items: center;

  p {
    width: 25px;
    margin: 0 10px;
    font-size: ${theme.textSizes.large};
  }
  p:first-child {
    margin-right: 20px;
  }
`

const ReactSliderContainer = styled(ReactSlider)`
  width: 90%;
`

const StyledTrack = styled.div`
  height: 2px;
  background: ${theme.colors.black};
`

const ReactSliderTrack = (props: any) => <StyledTrack {...props} />

const StyledThumb = styled.div`
  position: relative;
  width: 0;
  height: 0;
  top: 0;

  div {
    position: absolute;
    left: calc(50% + 12px);
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${theme.colors.black};
  }
`

const ReactSliderThumb = (props: any) => (
  <StyledThumb
    {...props}
    style={{
      ...props?.style,
      left: `calc(${props?.style?.left} - 12px)`
    }}
  >
    <div />
  </StyledThumb>
)

const StyledMark = styled.div`
  width: 2px;
  height: 10px;
  top: -10px;
  background-color: ${theme.colors.black};

  &:last-child {
    margin-left: -1px;
  }
`

const ReactSliderMark = (props: any) => <StyledMark {...props} />

const CommentContainer = styled.div`
  width: 20%;
  cursor: pointer;
`

const InputError = styled.span`
  font-size: ${theme.textSizes.smaller};
  line-height: 16px;
  margin-top: 5px;
  color: ${theme.colors.red};
`

const ReportOptionsList = styled.ul`
  list-style: none;
  color: ${theme.colors.black};
  padding: 0;
  margin: 0;
  max-height: 240px;
  overflow-y: auto;
`

const ReportOptionsListItem = styled.li`
  cursor: pointer;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

  b {
    font-size: ${theme.textSizes.small};
    line-height: 1.6;
  }

  p {
    margin: 0;
    padding: 0;
    color: ${theme.colors.lightGray};
  }
`

const SelectedReportsList = styled.ul`
  list-style: none;
  color: ${theme.colors.black};
  margin: 0;
  padding: 0;
`

const SelectedReportsListItem = styled.li`
  position: relative;
  padding: 10px 25px;

  b {
    font-size: ${theme.textSizes.small};
    line-height: 1.6;
    font-weight: 500;
  }

  p {
    size: ${theme.textSizes.small};
    margin: 0;
    padding: 0;
  }

  svg {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: calc(50% - 10px);
  }
`

const ArchiveImage = styled.img`
  width: 240px;
  height: 400px;
`

const ArchiveImageInputContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 400px;
  width: 240px;
  background-color: ${theme.colors.lighterGray};
  opacity: 0.4;
  margin: 10px 5px 10px 0;

  &::after {
    width: 24px;
    height: 24px;
    background: url(${IconCameraSrc});
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export {
  ArchiveImage,
  ArchiveImageInputContainer,
  CheckboxGroupContainer,
  CheckboxGroupItemLabel,
  CheckboxGroupLabel,
  CheckboxWrapContainer,
  CommentContainer,
  DropdownUserContainer,
  DropdownUserListContainer,
  DropdownUserListItem,
  InputCollapsableContainer,
  InputContainer,
  InputDateContainer,
  InputError,
  InputGroup,
  InputIconContainer,
  InputLabel,
  InputTimeContainer,
  InputTimeGroup,
  InputTimeLabel,
  MaskedInputContainer,
  PhoneInputContainer,
  RadioGroupContainer,
  RadioGroupItemLabel,
  RadioGroupLabel,
  ReactSliderContainer,
  ReactSliderContent,
  ReactSliderLabel,
  ReactSliderMark,
  ReactSliderThumb,
  ReactSliderTrack,
  ReportOptionsList,
  ReportOptionsListItem,
  SelectContainer,
  SelectedReportsList,
  SelectedReportsListItem,
  SelectedUserItem,
  SuccessLabel,
  TextAreaContainer
}
