import i18n from 'i18n-js'
import memoize from 'lodash.memoize'

const translationGetters = (lang: string) => {
  const englishLocales = [
    'en',
    'en-AS',
    'en-AU',
    'en-BB',
    'en-BE',
    'en-BM',
    'en-BW',
    'en-BZ',
    'en-CA',
    'en-GB',
    'en-GU',
    'en-HK',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MH',
    'en-MP',
    'en-MT',
    'en-MU',
    'en-NA',
    'en-NZ',
    'en-PH',
    'en-PK',
    'en-RW',
    'en-SG',
    'en-SH',
    'en-TT',
    'en-UM',
    'en-US',
    'en-VI',
    'en-ZA',
    'en-ZW'
  ]

  if (englishLocales.includes(lang)) {
    return require('../assets/locales/en.json')
  }

  // if (norwegianLocales.includes(lang)) {
  //   return require('../assets/locales/nb.json')
  // }

  return require('../assets/locales/se.json')
}

const translate = memoize(
  (key, config = null) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
)

const setI18nConfig = () => {
  const fallback = { languageTag: 'sv' }
  const { languageTag } = { languageTag: navigator.language } || fallback

  if (translate && translate.cache && translate.cache.clear)
    translate.cache.clear()

  i18n.translations = { [languageTag]: translationGetters(languageTag) }
  i18n.locale = languageTag
  i18n.missingTranslation = () => null
  Promise.resolve()
}

export { setI18nConfig, translate as t, translate }
