import {
  BikeEquipmentInput,
  DateInput,
  PhoneInput,
  RadioGroupInput,
  RelativeInput,
  SelectInput,
  SkiEquipmentInput,
  TextInput
} from 'components'
import {
  BIKE_EQUIPMENT_OPTIONS,
  BOOLEAN_OPTIONS,
  COUNTRY_OPTIONS,
  GENDER_OPTIONS,
  IS_RENTED_OPTIONS,
  SKILL_LEVEL_OPTIONS,
  SKI_EQUIPMENT_OPTIONS
} from 'constants/options'
import { nationalities } from 'containers/Statistics/Filters/static'
import { FieldArray, useFormikContext } from 'formik'
import { t } from 'i18n'
import { useEffect, useState } from 'react'
import { If } from 'react-extras'
import { PersonalInformationConsent, Report, Seasons } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, FormSectionSubtitle, RowItem } from '../styled'
import ConsentPersonalInfoSection from './ConsentPersonalInfoSection'

const PersonalInformationSection = () => {
  const [showPersonInfoForm, setShowPersonInfoForm] = useState<boolean>(false)
  const [showRelativeInfo, setShowRelativeInfo] = useState<boolean>(false)
  const [showEquipmentButton, setShowEquipmentButton] = useState<boolean>(false)
  const [showSkillsButton, setShowSkillsButton] = useState<boolean>(false)

  const {
    values: { personalInformation, season },
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormikContext<Report>()

  const { personalInformationConsent } = personalInformation

  const patientName =
    personalInformation?.firstName && personalInformation?.lastName
      ? `${personalInformation?.firstName} ${personalInformation?.lastName}`
      : ''

  useEffect(() => {
    const handleFormOptions = () => {
      switch (personalInformationConsent) {
        case PersonalInformationConsent.PERMISSION_CONSENTED:
        case PersonalInformationConsent.CONSENT_TO_WRITTEN_REPORT:
          setShowPersonInfoForm(true)
          setShowRelativeInfo(true)
          setShowEquipmentButton(true)
          setShowSkillsButton(true)
          break
        case PersonalInformationConsent.NOT_RESPONSIVE_CANT_GET_CONSENT:
        case PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT:
          setShowPersonInfoForm(false)
          setShowRelativeInfo(true)
          setShowEquipmentButton(true)
          setShowSkillsButton(true)
          break
      }
    }

    handleFormOptions()
  }, [personalInformationConsent])

  return (
    <>
      <FormTitle title="personalInformation" hasDisclaimer />
      <FormSectionSubtitle>{t`consentToCollectPersonalData`}</FormSectionSubtitle>
      <FlexRow>
        <ConsentPersonalInfoSection />
      </FlexRow>
      <FormSectionSubtitle>{t`personalInfo`}</FormSectionSubtitle>
      <If condition={showPersonInfoForm}>
        <FlexRow>
          <RowItem width={20}>
            <TextInput
              type="text"
              name="personalInformation.firstName"
              label="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.firstName || ''}
            />
          </RowItem>
          <RowItem width={20}>
            <TextInput
              type="text"
              name="personalInformation.lastName"
              label="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.lastName || ''}
            />
          </RowItem>
          <RowItem width={20}>
            <PhoneInput
              name="personalInformation.phoneNumber"
              label="phoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.phoneNumber}
            />
          </RowItem>
        </FlexRow>
        <FlexRow>
          <RowItem width={15}>
            <DateInput
              name="personalInformation.birthDate"
              label="birthDate"
              onChange={setFieldValue}
              value={
                personalInformation?.birthDate
                  ? new Date(personalInformation?.birthDate)
                  : new Date()
              }
              format="y/MM/dd"
            />
          </RowItem>
          <RowItem width={20}>
            <SelectInput
              name="personalInformation.gender"
              label="gender"
              onChange={handleChange}
              onBlur={handleBlur}
              options={GENDER_OPTIONS}
              value={personalInformation?.gender}
            />
          </RowItem>
          <RowItem width={20}>
            <SelectInput
              name="personalInformation.nationality"
              label="nationality"
              onChange={handleChange}
              onBlur={handleBlur}
              translateText={false}
              options={nationalities(t)}
              value={personalInformation?.nationality}
            />
          </RowItem>
        </FlexRow>
        <FormSectionSubtitle>{t`residence`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem width={20}>
            <TextInput
              type="text"
              name="personalInformation.address"
              label="address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.address || ''}
            />
          </RowItem>
        </FlexRow>
        <FlexRow>
          <RowItem width={10}>
            <TextInput
              type="text"
              name="personalInformation.postalCode"
              label="postalCode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.postalCode || ''}
            />
          </RowItem>
          <RowItem width={20}>
            <TextInput
              type="text"
              name="personalInformation.place"
              label="place"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.place || ''}
            />
          </RowItem>
          <RowItem width={20}>
            <SelectInput
              name="personalInformation.country"
              label="country"
              onChange={handleChange}
              onBlur={handleBlur}
              // translateText={false}
              options={COUNTRY_OPTIONS}
              value={personalInformation?.country}
            />
          </RowItem>
        </FlexRow>
        <FormSectionSubtitle>{t`allergies`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem width={50}>
            <TextInput
              type="text"
              name="personalInformation.allergies"
              label="enterAllergiesThatPatientHas"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.allergies || ''}
            />
          </RowItem>
        </FlexRow>
        <FormSectionSubtitle>{t`fixedMedications`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem width={50}>
            <TextInput
              type="text"
              name="personalInformation.medications"
              label="enterMedicationsThatPatientIsUsing"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personalInformation?.medications || ''}
            />
          </RowItem>
        </FlexRow>
      </If>
      <If condition={showRelativeInfo}>
        <FormSectionSubtitle>{t`relatives`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem width={20}>
            <FieldArray
              name="personalInformation.relatives"
              render={arrayHelpers => (
                <RelativeInput
                  label="addRelatives"
                  patientName={patientName}
                  relatives={personalInformation?.relatives || []}
                  add={arrayHelpers.push}
                  update={arrayHelpers.replace}
                  remove={arrayHelpers.remove}
                />
              )}
            />
          </RowItem>
        </FlexRow>
      </If>
      <If condition={showSkillsButton}>
        <FormSectionSubtitle>{t`skills`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem>
            <RadioGroupInput
              name="personalInformation.skills.level"
              value={personalInformation?.skills?.level}
              options={SKILL_LEVEL_OPTIONS}
              onChange={setFieldValue}
            />
          </RowItem>
        </FlexRow>
      </If>
      <If condition={showEquipmentButton}>
        <If condition={season === Seasons.WINTER}>
          <FormSectionSubtitle>{t`injuryDuringInstructions`}</FormSectionSubtitle>
        </If>
        <If condition={season === Seasons.SUMMER}>
          <FormSectionSubtitle>{t`didTheInjuryHappenWithTheGuide`}</FormSectionSubtitle>
        </If>
        <FlexRow>
          <RowItem>
            <RadioGroupInput
              name="personalInformation.skills.introduction"
              value={personalInformation?.skills?.introduction}
              options={BOOLEAN_OPTIONS}
              onChange={setFieldValue}
            />
          </RowItem>
        </FlexRow>
        <If condition={season === Seasons.WINTER}>
          <FormSectionSubtitle>{t`skiEquipment`}</FormSectionSubtitle>
        </If>
        <If condition={season === Seasons.SUMMER}>
          <FormSectionSubtitle>{t`bikeEquipment`}</FormSectionSubtitle>
        </If>
        <FlexRow>
          <RowItem>
            <RadioGroupInput
              label="isRented"
              name="personalInformation.equipments.isRented"
              value={personalInformation?.equipments?.isRented}
              options={IS_RENTED_OPTIONS}
              onChange={setFieldValue}
            />
          </RowItem>
        </FlexRow>
        <FlexRow>
          <If condition={season === Seasons.WINTER}>
            <RowItem>
              <SkiEquipmentInput
                name="personalInformation.equipments.skiEquipment"
                value={personalInformation?.equipments?.skiEquipment}
                options={SKI_EQUIPMENT_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </If>
          <If condition={season === Seasons.SUMMER}>
            <RowItem>
              <BikeEquipmentInput
                name="personalInformation.equipments.bikeEquipment"
                value={personalInformation?.equipments?.bikeEquipment}
                options={BIKE_EQUIPMENT_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </If>
        </FlexRow>
        <FormSectionSubtitle>{t`safetyEquipment`}</FormSectionSubtitle>
        <FlexRow>
          <RowItem>
            <RadioGroupInput
              name="personalInformation.equipments.safetyEquipments.isRented"
              label="isEquipmentRented"
              value={
                personalInformation?.equipments?.safetyEquipments?.isRented
              }
              options={IS_RENTED_OPTIONS}
              onChange={setFieldValue}
            />
          </RowItem>
        </FlexRow>
        <If condition={season === Seasons.WINTER}>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.helmet"
                label="helmet"
                value={
                  personalInformation?.equipments?.safetyEquipments?.helmet
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.backProtector"
                label="backProtector"
                value={
                  personalInformation?.equipments?.safetyEquipments
                    ?.backProtector
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.wristProtector"
                label="wristProtector"
                value={
                  personalInformation?.equipments?.safetyEquipments
                    ?.wristProtector
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
        </If>
        <If condition={season === Seasons.SUMMER}>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.halfHelmet"
                label="halfHelmet"
                value={
                  personalInformation?.equipments?.safetyEquipments?.halfHelmet
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.helmet"
                label="helmet"
                value={
                  personalInformation?.equipments?.safetyEquipments?.helmet
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.gloves"
                label="gloves"
                value={
                  personalInformation?.equipments?.safetyEquipments?.gloves
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.backPlate"
                label="backPlate"
                value={
                  personalInformation?.equipments?.safetyEquipments?.backPlate
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.armor"
                label="armor"
                value={personalInformation?.equipments?.safetyEquipments?.armor}
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.legKneeProtector"
                label="legKneeProtector"
                value={
                  personalInformation?.equipments?.safetyEquipments
                    ?.legKneeProtector
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.neckCollar"
                label="neckCollar"
                value={
                  personalInformation?.equipments?.safetyEquipments?.neckCollar
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.sack"
                label="sack"
                value={personalInformation?.equipments?.safetyEquipments?.sack}
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
          <FlexRow>
            <RowItem>
              <RadioGroupInput
                name="personalInformation.equipments.safetyEquipments.elbowProtection"
                label="elbowProtection"
                value={
                  personalInformation?.equipments?.safetyEquipments
                    ?.elbowProtection
                }
                options={BOOLEAN_OPTIONS}
                onChange={setFieldValue}
              />
            </RowItem>
          </FlexRow>
        </If>
      </If>
    </>
  )
}

export default PersonalInformationSection
